import axios from "axios";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactSelect from "react-select";
// import EventPage from "../../components/event/EventPage";
import LoadingContent from "../../components/LoadingContent";
import NoPhotosContent from "../../components/NoPhotosContent";
import PageBanner from "../../components/service/PageBanner";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Helmet } from "react-helmet";

var Api_url = process.env.REACT_APP_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class LOEvent extends Component {
    constructor() {
        super();
        this.state = {
            isClearable1: true,
            isDisabled1: false,
            isLoading1: false,
            isSearchable1: true,
            ZoneOptions: [],
            zoneId: 2,
            zoneName: "Zone I",
            LoadingFlag: false,
            TotalRecords: 0,
            PageNo: 1,
            Records: [],
            hasMore: true,
            ImagePath: '',
            isOpen: false,
        };
    }
    async fetchData(e) {
        console.log("fetchData called ");
        var ZoneId = e.value;
        console.log("ZoneId = " + ZoneId);
        console.log("PageNo=" + this.state.PageNo);
        await this.setState({ PageNo: 1, zoneId: e.value, zoneName: e.label, TotalRecords: 0, hasMore: true, Records: [] });
        console.log("TotalPage=" + this.state.TotalRecords + "; PageNo=" + this.state.PageNo + "; hasMore=" + this.state.hasMore);
        this.getAllRecords();
    }

    async getAllRecords() {
        console.log("getAllRecords called ");

        var PageNo = this.state.PageNo;
        var zone = this.state.zoneId;
        console.log("PageNo=" + PageNo + "zone=" + zone);

        var url = Api_url + `/api/jci-events/images?page=` + PageNo;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            Zone: zone
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag: false });

                var flag = res.data.flag;
                var records = res.data.records;
                var PageItems = records.PageItems;
                var TotalItems = records.TotalItems;
                var TotalPage = records.TotalPage;
                console.log({ records });
                console.log({ PageItems });
                console.log({ TotalItems });

                if (flag) {
                    console.log("TotalPage=" + TotalPage + "; PageNo=" + this.state.PageNo + "; hasMore=" + this.state.hasMore);
                    if (TotalPage > this.state.PageNo) {
                        this.setState({ PageNo: this.state.PageNo + 1 });
                    } else {
                        this.setState({ hasMore: false });
                    }

                    var receive_data = records.Images;
                    var current_data = this.state.Records;
                    var result = current_data.concat(receive_data);
                    var n1 = result.length;
                    console.log("n1=" + n1);

                    this.setState({ Records: result, TotalRecords: n1 });
                } else {
                    this.setState({ hasMore: false });
                }
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getZones() {
        console.log("getZones called ");
        var url = Api_url + `/api/wm/zone/options`;
        console.log("url=" + url);
        var postData = {
            additional: {
                UserInterface: 2
            }
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ isLoading1: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var result = res.data.records;
                console.log("result=" + JSON.stringify(result));
                var options = result.map(d => ({
                    value: d.value,
                    label: d.text
                }));
                console.log("options=" + JSON.stringify(options));
                this.setState({ ZoneOptions: options });
                this.setState({ isLoading1: false });
                this.fetchData({ value: this.state.zoneId, label: this.state.zoneName });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        window.scrollTo(0, 0);
        this.getZones();
    }
    // componentDidMount() {
    //     console.log("componentDidMount called");
    //     window.scrollTo(0, 0);
    // }
    render() {
        const { ImagePath, isOpen } = this.state;
        return (
            <>
                <Helmet>
                    <title>LO Event | JCI India | Be Better</title>
                    {/* <meta name='description' content='About-JCI India' /> */}
                </Helmet>
                <PageBanner bannerDetails={{ bannerTitle: "LO Event", bannerActive: "Event" }} />
                <Container>
                    <br /><br /><br />
                    <Row className=" align-items-center">
                        <Col md={6} lg={6}>Select Zone</Col>
                        <Col md={6} lg={6} style={{ zIndex: 10 }}>
                            <ReactSelect
                                options={this.state.ZoneOptions}
                                onChange={this.fetchData.bind(this)}
                                isClearable={this.state.isClearable1}
                                isSearchable={this.state.isSearchable1}
                                isDisabled={this.state.isDisabled1}
                                isLoading={this.state.isLoading1}
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col md={12} lg={12}> <h3> {this.state.zoneName} </h3> </Col>
                    </Row>
                </Container>
                <section className="gallery-page pt-120 pb-120">
                    <div className="container">
                        <InfiniteScroll
                            dataLength={this.state.Records.length} //This is important field to render the next data
                            next={this.getAllRecords}
                            hasMore={this.state.hasMore}
                            loader={''}
                            scrollThreshold={0.7}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>That's all for now. Come back later for more.</b>
                                </p>
                            }
                        >
                            <div>
                                <Row>
                                    {this.state.Records.length > 0 ?
                                        this.state.Records.map(item => (
                                            <Col xl={3} lg={4} sm={6} >
                                                {isOpen && (
                                                    <Lightbox
                                                        mainSrc={ImagePath}
                                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                                    />
                                                )}
                                                {console.log("isOpen = ", isOpen)}
                                                <img src={item.ImagePath} onClick={() => this.setState({ isOpen: true, ImagePath: item.ImagePath })} style={{ width: 330, height: 200, borderRadius: 15, objectFit: 'cover' }} alt="" />
                                                <p style={{ textAlign: 'center' }}>{item.SettingsValuesTitle}</p>



                                                {/* <div>
                                                    <EventPage image={item.ImagePath} />
                                                    <p style={{ textAlign: 'center' }}>{item.ImageDescription}</p>
                                                </div> */}
                                                <br />
                                            </Col>
                                        )) : (this.state.LoadingFlag ? '' : '')
                                    }
                                </Row>
                            </div>
                            {this.state.LoadingFlag ? <LoadingContent /> : (this.state.Records.length === 0 ? <NoPhotosContent /> : '')}
                        </InfiniteScroll>
                    </div>
                </section>
            </>
        );
    }
}