import React, { Component } from "react";
import CountUp from "react-countup";

const aboutImage = process.env.PUBLIC_URL + "/assets/images/about/jci-vector.gif";

export default class AboutJCISection extends Component {

    render() {
        return (
            <>
                <section className="about-section p-r z-1 pt-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about-four_content-box mb-10">
                                    <div className="section-title section-title-left wow fadeInUp">
                                        <span className="sub-title">About JCI</span>
                                        <h2>How it all began!</h2>
                                    </div>
                                    <p>
                                        Junior Chamber International (JCI) is a worldwide federation of young leaders and entrepreneurs with nearly five lakh active members and millions of alumni spread across more than 115 countries.
                                    </p>
                                    <div className="choose-item-list wow fadeInDown">
                                        <div className="single-choose-item mb-30">
                                            <div className="text">
                                                <h5 className="text-justify"> JCI orgin can be traced back to 1915 to the city of St. Louis, Missouri, USA where Sir Henry Giessenbier with 32 other young men established Young Men's Progressive Civic Association (YMPCA) </h5>
                                            </div>
                                        </div>
                                        <div className="single-choose-item mb-30">
                                            <div className="text">
                                                <h5 className="text-justify"> In 1916, the name was changed from YMPCA to "Junior Citizens", commonly called "JCs", which later became "Jaycees" </h5>
                                            </div>
                                        </div>
                                        <div className="single-choose-item mb-30">
                                            <div className="text">
                                                <h5 className="text-justify"> Their work so impressed the St. Louis Junior chamber of Commerce that they asked these young men in 1918 to adopt the name “Junior Chamber of Commerce” which later became “Junior Chamber” </h5>
                                            </div>
                                        </div>
                                        <div className="single-choose-item mb-30">
                                            <div className="text">
                                                <h5 className="text-justify"> By 1944, the movement had spread through 18 countries officially forming "Junior Chamber International", welcoming people between age 18 and 40 years </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-four_image-box text-right p-r mb-10 wow fadeInRight">
                                    <img src={aboutImage} className="about-img_one" alt="" height={500} />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="counter-wrap-one" style={{ width: "350px" }}>
                                        <div className="counter-inner-box" style={{ height: "150px" }}>
                                            <div className="text-center">
                                                <br />
                                                <h2 className="">
                                                    <CountUp
                                                        start={0}
                                                        end={115}
                                                        duration={3}
                                                    />
                                                </h2>
                                                <p>Spread across in countries</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="container d-flex justify-content-center mt-40">
                        <div className="counter-wrap-one wow fadeInDown" style={{ width: "350px" }}>
                            <div className="counter-inner-box" style={{ height: "150px" }}>
                                <div className="text-center">
                                    <br />
                                    <h2 className="">
                                        <CountUp
                                            start={0}
                                            end={115}
                                            duration={3}
                                        />
                                    </h2>
                                    <p>Spread across in countries</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </section>
            </>
        );
    }
}