import React, { Component } from "react";

const founderImage = process.env.PUBLIC_URL + "/assets/images/about/founder.jpg";

export default class AboutTestimoinal extends Component {
    render() {
        return (
            <>
                <section class="testimonial-three pt-90">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-4">
                                <div class="testimonial-item-three d-flex">
                                    {/* <div class="author-thumb"> */}
                                        <img src={founderImage} alt="founderImage" style={{ width: "400px", height: "400px" }} />
                                    {/* </div> */}
                                </div>
                            </div>

                            <div class="col-lg-8">
                                <div class="testimonial-item-three d-flex mt-60">
                                    <div class="testimonial-content">
                                        <p className="text-justify">"From within the walls of the soul of this organization wherein the foundation of character and good citizenship are laid, I hope a message will come in the sometime of tomorrow that will stir the people towards the establishment of a permanent and everlasting world peace."</p>
                                        <div class="author-title-qoute d-flex">
                                            <div class="quote">
                                                <i class="fas fa-quote-right"></i>
                                            </div>
                                            <div class="author-title">
                                                <h4>Sir Henry Giessenbier</h4>
                                                <p class="position">Founder</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </>
        );
    }
}