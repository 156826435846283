import React, { Component } from "react";

export default class ContactInformationOne extends Component {
    render() {
        return (
            <>
                <section className="contact-information-one p-r z-1 pt-130 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="contact-two_information-box">
                                    <div className="section-title section-title-left mb-40 wow fadeInUp">
                                        <span className="sub-title">Connect With Us</span>
                                        <h2>Feel free to contact us.</h2>
                                    </div>

                                    <div className="row mb-50">
                                        <div className="col-lg-8">
                                            <p>Join the Biggest Organization with young active citizens and be a part of change.</p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="information-item-two info-one mb-30 wow fadeInDown">
                                                <div className="icon">
                                                    <i className="far fa-map-marker-alt"></i>
                                                </div>
                                                <div className="info">
                                                    <h5>Locations</h5>
                                                    <p>
                                                        <a href="https://goo.gl/maps/wSStE4sfa76unZTZ8" target="_blank" rel="noopener noreferrer">National Head Quarter <br />
                                                            506 Windfall, Sahar Plaza, J.B. Nagar,
                                                            Andheri (EAST)
                                                            Mumbai 400 05
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="information-item-two mb-30 info-two wow fadeInUp">
                                                <div className="icon">
                                                    <i className="far fa-envelope-open-text"></i>
                                                </div>
                                                <div className="info">
                                                    <h5>Email Address</h5>
                                                    <p><a href="mailto:support@jciindia.in">support@jciindia.in</a></p>
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="information-item-two mb-30 info-three wow fadeInDown">
                                                <div className="icon">
                                                    <i className="far fa-phone"></i>
                                                </div>
                                                <div className="info">
                                                    <h5>Phone Number</h5>
                                                    <p><a href="tel:(022)-71117112">(022)-71117112</a></p>
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col-lg-12">
                                            <h5>Be Better. Today. Tomorrow. Everyday.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="contact-page-map">
                    <div class="map-box">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d120636.1246469079!2d72.79900094342625!3d19.112968055345508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c97629cdd3c5%3A0x35226d4262ca91e!2sJCI%20INDIA%20NATIONAL%20HEAD%20QUARTER!5e0!3m2!1sen!2sin!4v1616677978152!5m2!1sen!2sin" title="google-map"
                        ></iframe>
                    </div>
                </section>
            </>
        );
    }
}