import React from "react";
import axios from "axios";

import InfiniteScroll from 'react-infinite-scroll-component';
// import EventPage from "./EventPage";
import LoadingContent from "../LoadingContent";
import { Col, Row } from "react-bootstrap";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

export default class NationalEventPage extends React.Component {

    constructor() {
        super();
        this.state = {
            Records: [],
            LoadingFlag: false,
            TotalRecords: 0,
            PageNo: 1,
            hasMore: true,
            FilePath: '',
            isOpen: false,
        }
    };

    getAllRecords = async () =>{
        console.log("getAllRecords called ");

        this.setState({ LoadingFlag: true });

        var PageNo = this.state.PageNo;
        console.log("PageNo=" + PageNo);

        var url = Api_url + `/api/national-events/lists?page=` + PageNo;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "jci_india_website",
            SettingsName: "national_events",
        };
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var current_data = this.state.Records;
                var records = res.data.records;
                console.log({ records });
                var receive_data = records.SettingsValues;
                var result = current_data.concat(receive_data);
                var n1 = result.length;
                console.log("n1=" + n1);

                var TotalRecords = records.TotalRecords;
                console.log({ TotalRecords });

                var CurrentPage = records.CurrentPage;
                console.log({ CurrentPage });

                var TotalPage = records.TotalPage;
                console.log({ TotalPage });

                var moreFlag = CurrentPage !== TotalPage && CurrentPage > 0 ? true : false;
                console.log({ moreFlag });

                this.setState({ LoadingFlag: false, Records: result, TotalRecords: TotalRecords, PageNo: CurrentPage+1, hasMore: moreFlag });

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        this.getAllRecords();
    }

    render() {
        const { FilePath, isOpen } = this.state;
        return (
            <section className="gallery-page pt-120 pb-120">
                {/* <p>Next Page No {this.state.PageNo}</p>
                <p>Records {this.state.TotalRecords}</p>
                <p>hasMore {this.state.hasMore}</p> */}
                <div className="container">
                    <InfiniteScroll
                        dataLength={this.state.Records.length} //This is important field to render the next data
                        next={this.getAllRecords}
                        style={{ overflow: 'hidden' }}
                        hasMore={this.state.hasMore}
                        loader={''}
                        scrollThreshold={0.7}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>That's all for now. Come back later for more.</b>
                            </p>
                        }
                    >
                        <Row>
                            {this.state.Records.length > 0 ?
                                this.state.Records.map(item => (
                                    <Col xl={3} lg={4} sm={6} >
                                        {isOpen && (
                                            <Lightbox
                                                mainSrc={FilePath}
                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                            />
                                        )}
                                        {console.log("isOpen = ", isOpen)}
                                        <LazyLoadImage effect="blur" src={item.FilePath} onClick={() => this.setState({ isOpen: true, FilePath: item.FilePath })} style={{ width: 330, height: 200, borderRadius: 15, objectFit: 'cover' }} alt="" />
                                        <p style={{ textAlign: 'center' }}>{item.SettingsValuesTitle}</p>

                                        {/* <div>
                                            <EventPage image={item.FilePath} />
                                            <p style={{ textAlign: 'center' }}>{item.SettingsValuesTitle}</p>
                                        </div> */}
                                    </Col>
                                ))
                                :
                                ''
                            }

                        </Row>
                    </InfiniteScroll>
                    {this.state.LoadingFlag ? <LoadingContent /> : ''}
                </div>
            </section >
        );
    }
}