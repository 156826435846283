import React, { Component } from "react";

const bgImage1 = process.env.PUBLIC_URL + "/assets/images/bg/page-bg-1.jpg";

export default class ContentBanner extends Component {

    render() {
        return (
            <>
                <section className="page-banner bg_cover position-relative z-1" style={{ backgroundImage: `url(${bgImage1})` }} >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="page-title">
                                    <h2>{this.props.bannerDetails.bannerTitle}</h2>
                                    <ul className="breadcrumbs-link">
                                        <li><a href="index.html">Home</a></li>
                                        <li className="active">{this.props.bannerDetails.bannerActive}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}