import React, { Component } from "react";

const jciIndiaImage = process.env.PUBLIC_URL + "/assets/images/about/jci-india.png";

export default class AboutJCIIndiaSection extends Component {

    render() {
        return (
            <>
                <section className="about-section p-r z-1 pt-80 pb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="about-four_content-box mb-10">
                                    <div className="section-title section-title-left wow fadeInUp">

                                        <img src={jciIndiaImage} className="about-img_one" alt="" height={700} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-four_content-box mb-10">
                                    <div className="section-title section-title-left wow fadeInUp">
                                        <span className="sub-title">About JCI India</span>
                                        <h4>Welcome to India's largest organization with young and active leaders</h4>
                                    </div>
                                    <br />
                                    <p className="text-justify" style={{ fontSize: "20px", fontWeight: "bold"}}>
                                        JCI India is a voluntary organization, membership based NGO
                                        working in India since 1949 for developing the leadership skills
                                        of young men and women of this country. It is affiliated to
                                        Junior Chamber International(JCI),a worldwide federation of
                                        young leaders and entrepreneurs founded in 1944, having
                                        headquarter at Chester Field USA. Currently it has over 200,000
                                        active members and more than one million graduates, in over 100
                                        countries and 6,000 communities.
                                    </p>

                                    <p className="text-justify" style={{ fontSize: "20px", fontWeight: "bold"}}>
                                        JCI India is the Second largest Member Nation of Junior Chamber
                                        International. Currently we are active in more than 26 states
                                        and union territories across India.
                                    </p>

                                    <p className="text-justify" style={{ fontSize: "20px", fontWeight: "bold"}}>
                                        The membership is offered to everybody regardless of color, cast
                                        and creed between the age of 18 -40 years. Junior Chamber
                                        International India is registered under Societies Registration
                                        Act, Bombay Public Trust Act and Income Tax Act of India.
                                    </p>

                                    <p className="text-justify" style={{ fontSize: "20px", fontWeight: "bold"}}>
                                        In the last 64 years we are able to produce thousands of social
                                        and business leaders all over the country through our intensive
                                        project based training activities
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}