import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Header extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <div className="top-bar-one dark-black-bg">
                        <div className="container-fluid">
                            <div className="row align-items-right">
                                <div className="col-xl-3 col-lg-12 col-md-12">
                                    <div className="top-left d-flex align-items-right">
                                        <span className="text">Welcome to JCI India</span>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-12 col-md-12">
                                    <div className="top-right">
                                        <span className="text">
                                            <ul className="social-link">
                                                <li>
                                                    <Link to="/online-payment"><i className="fas fa-rupee-sign"></i>Payment</Link>
                                                </li>
                                                <li>
                                                    <a href="https://foundation.jciindia.in/" target="_blank" rel="noopener noreferrer"><i className="fas fa-hand-holding-heart"></i>JCI India Foundation</a>
                                                </li>
                                                <li>
                                                    <a href="https://seniors.jciindia.in/" target="_blank" rel="noopener noreferrer">
                                                        <i className="fas fa-user-graduate"></i>JCI Alumni Club</a>
                                                </li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-12 col-md-12">
                                    <div className="top-right">
                                        <ul className="social-link">
                                            <li><a href="https://www.facebook.com/jciindiaofficial/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><Link><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link><i className="fab fa-linkedin"></i></Link></li>
                                            <li><a href="https://www.youtube.com/channel/UC1HA8mFf3c0E0rdHg1O2MIA" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="header-middle">
                        <div className="container-1350">
                            <div className="row align-items-center">
                                <div className="col-xl-4 d-xl-block d-lg-none">
                                    <div className="site-branding d-lg-block d-none">
                                        <Link to={"/"}> <img src="/assets/images/logo/jci-logo.png" alt="Jci-India-Logo" style={{ width: "250px", height: "100px" }}></img> </Link>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-12">

                                    <div className="site-branding d-lg-block d-none">
                                        <div className="contact-information">
                                            <div className="information-item_one d-flex">
                                                <div className="icon">
                                                    {/* <i className="far fa-phone"></i> */}
                                                    <i className="flaticon-placeholder"></i>
                                                </div>
                                                {/* <div className="info">
                                                <h5 className="mb-1">Contact Us</h5>
                                                <p>(022)-71117112</p>
                                                    </div> */}
                                                <div className="info">
                                                    <h5 className="mb-1">Locations</h5>
                                                    <p>National Head Quarter <br/> 506 Windfall, Sahar Plaza, J.B. Nagar, Andheri (EAST) Mumbai 400 059</p>
                                                </div>
                                            </div>

                                            <div className="information-item_one d-flex">
                                                <div className="icon">
                                                    {/* <i className="far fa-envelope-open-text"></i> */}
                                                    <i className="flaticon-email"></i>
                                                </div>
                                                <div className="info">
                                                    <h5 className="mb-1">Email Us</h5>
                                                    <p><a href="mailto:support@jciindia.in">support@jciindia.in</a></p>
                                                </div>
                                            </div>

                                            <div className="button text-md-right text-sm-center">
                                                <a href="https://members.jciindia.in/" target="_blank" style={{ width: '200px' }} rel="noopener noreferrer" className="main-btn btn-yellow">Members Login</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-navigation navigation-one">
                        <div className="nav-overlay"></div>
                        <div className="container-1350">
                            <div className="primary-menu">
                                <div className="nav-inner-menu">
                                    <div className="nav-menu">
                                        <div className="navbar-close">
                                            <i className="far fa-times"></i>
                                        </div>
                                        <div className="site-branding ml-2 mb-20">
                                            {/* <p>hello</p> */}
                                            <p className="mt-0">
                                                Welcome to JCI India
                                            </p>
                                            <div className="mt-20">
                                                <Link to={"/"}> <img src="/assets/images/logo/jci-logo.png" alt="Jci-India-Logo" style={{ width: "140px", height: "80px" }}></img> </Link>
                                            </div>
                                            <ul className="social-link mt-20" style={{ color: "blue" }}>
                                                <li>
                                                    <Link to="/online-payment"><i className="fas fa-rupee-sign"></i> Payment</Link>
                                                </li> <br />
                                                <li>
                                                    <a href="https://foundation.jciindia.in/" target="_blank" rel="noopener noreferrer"><i className="fas fa-hand-holding-heart"></i> JCI India Foundation</a>
                                                </li> <br />
                                                <li>
                                                    <a href="https://seniors.jciindia.in/" target="_blank" rel="noopener noreferrer">
                                                        <i className="fas fa-user-graduate"></i> Senior Members Association</a>
                                                </li> <br />
                                                <li>
                                                    <a href="https://members.jciindia.in/" target="_blank" rel="noopener noreferrer"><i className="fas fa-sign-in-alt" style={{fontFamily: "Open Sans bold"}}></i> Members Login</a>
                                                </li>
                                            </ul>

                                        </div>
                                        <nav className="main-menu">
                                            <ul>
                                                <li><Link to="/">Home</Link></li>
                                                <li className="menu-item has-children"><a href={onclick = "return: false"}>About Us</a>
                                                    <ul className="sub-menu">
                                                        <li><Link to="/about-jci">About JCI</Link></li>
                                                        <li><Link to="/about-jci-india">JCI India</Link></li>
                                                        <li><Link to="/about-np-corner">NP Corner</Link></li>
                                                        <li>
                                                            <a href="http://jci.cc/" target="_blank" rel="noopener noreferrer">
                                                                JCI Global
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                {/* <li><Link to="/about-us">About</Link></li> */}
                                                <li className="menu-item has-children"><a href={onclick = "return: false"}>Team</a>
                                                    <ul className="sub-menu">
                                                        <li><Link to="/team/national-governing-board">National Governing Board</Link></li>
                                                        <li><Link to="/team/zone-governing-board">Zone Governing Board</Link></li>
                                                        <li><Link to="/team/national-committees">National Committees</Link></li>
                                                        <li><Link to="/team/nom-coordinators">National Coordinators</Link></li>
                                                        <li><Link to="/team/past-national-presidents">Past National Presidents</Link></li>
                                                        <li><Link to="/team/jci-india-nhq-staffs">JCI India NHQ</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="menu-item has-children"><a href={onclick = "return: false"}>Event</a>
                                                    <ul className="sub-menu">
                                                        <li><Link to="event/national-event">National Event</Link></li>
                                                        <li><Link to="event/zone-event">Zone Event</Link></li>
                                                        <li><Link to="event/lo-event">LO Event</Link></li>
                                                        <li>
                                                            <a href="https://awards.jciindia.in" target="_blank" rel="noopener noreferrer">Awards Bidding Portal</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="menu-item has-children"><a href={onclick = "return: false"}>Program</a>
                                                    <ul className="sub-menu">
                                                        <li><Link to="program/national-program">National Program</Link></li>
                                                        <li>
                                                            <a href="http://www.jciindiacommunitydevelopment.in" target="_blank" rel="noopener noreferrer">Community Development Reporting</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><Link to="/gallery">Gallery</Link></li>
                                                <li><Link to="/download">Download</Link></li>
                                                <li><Link to="/contact-us">Contact</Link></li>
                                            </ul>
                                        </nav>
                                        <div className="site-branding ml-2 mt-60 mb-30">
                                            <div className="contact-information">
                                                <div className="information-item_one d-flex">
                                                    <div className="icon">
                                                        <i className="far fa-phone"></i>
                                                    </div>
                                                    <div className="info">
                                                        <h5 className="mb-1">Contact Us</h5>
                                                        <p>(022)-71117112</p>
                                                    </div>
                                                </div>
                                                <div className="information-item_one d-flex mt-20">
                                                    <div className="icon">
                                                        <i className="far fa-envelope-open-text"></i>
                                                    </div>
                                                    <div className="info">
                                                        <h5 className="mb-1">Email Us</h5>
                                                        <p><a href="mailto:support@jciindia.in">support@jciindia.in</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="site-branding mb-20">
                            {/* <p>hello</p> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col-8">
                                        <Link className="brand-logo"><img src="/assets/images/logo/jci-logo.png" alt="Jci-India" height={80} width={150} /></Link>
                                    </div>
                                    <div className="col-3">
                                        <div className="nav-right-item">
                                            <div className="navbar-toggler">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>

        );
    }
}

