import React, { Component } from "react";
// import { Link,useParams } from "react-router-dom";
import { Link } from "react-router-dom";

export default class Program extends Component {
    render() {
        return (
            <>
                <section className="category-one p-r z-1 pt-100">
                    <div className="shape shape-one"><span><img src="assets/images/shape/leaf-1.png" alt="" /></span></div>
                    <div className="shape shape-two"><span><img src="assets/images/shape/leaf-2.png" alt="" /></span></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="section-title text-center mb-50 wow fadeInUp">
                                    <span className="sub-title">National Programs</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {this.props.data.map((event, index, key = { index }) => (
                                <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                                    <div className="single-category-item mb-25 wow fadeInUp">
                                        <div className="category-img">
                                            <img src={event.EventImagePath} alt="category" width="100px" height="300px" />
                                        </div>
                                        <div className="category-info d-flex justify-content-between align-items-center">
                                            <h3 className="title">
                                                {/* <Link to={"/program/national-program-detail/"} state={{id:event.EventsId}}>{event.EventTitle}</Link> */}
                                                <Link to={"/program/national-program-detail?id="+event.EventsId}>{event.EventTitle}</Link>
                                                {/* <Link to={event.EventsId} children={<Child />}>{event.EventTitle}</Link> */}
                                            </h3>
                                            <i className="far fa-long-arrow-right"></i>
                                        </div>
                                        <div>
                                            <p>Date : {event.StartDate}</p>
                                            <p>Time : {event.TimeTxt}</p>
                                            <p>Location : {event.EventLocation}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}