import React, { Component } from "react";
import axios from "axios";
var Api_url = process.env.REACT_APP_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class ProgramDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            Event: {},
            LoadingFlag: false,
            EventFlag: false
        };
    }
    async getDetails(id) {
        console.log("getDetails called ");

        // var id = this.state.id;
        console.log("id=" + id);

        if (id > 0) {
            var url = Api_url + `/api/programs/event-details`;
            console.log("url=" + url);
            var postData = {
                UserInterface: 1,
                EventCode: id,
                OrganizationTypeCode: 1
            };
            this.setState({ LoadingFlag: true });
            console.log({ postData });
            await axios
                .post(url, postData, axiosConfig)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    console.log("res=" + JSON.stringify(res));

                    this.setState({ LoadingFlag: false });

                    var flag = res.data.flag;
                    var records = res.data.records;
                    console.log({ flag, records });

                    this.setState({ EventFlag: flag, Event: records });
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                });
        }
    };

    componentDidMount() {
        console.log("program detail componentDidMount called ");
        window.scrollTo(0, 0);
        let currentLocation = this.props.data;
        currentLocation = currentLocation.toString();
        console.log({ currentLocation });
        let searchParams = currentLocation.split('id=');
        var id = searchParams[1] ? parseInt(searchParams[1]) : '';
        console.log({ searchParams });
        console.log({ id });
        this.getDetails(id);
    }
    render() {
        return (
            <>
                <section className="project-details-page pt-70 wow fadeInUp">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="project-details-wrapper">
                                    <div className="project-content pb-20">
                                        <div className="content-box">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <h3 className="title mb-15">{this.state.Event.EventTitle}</h3>
                                                    <img src={this.state.Event.EventImagePath} style={{ width: 430, height: 240, borderRadius: 15, objectFit: 'cover' }} alt="" />
                                                </div>
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                            <div className="project-info-box mb-45">
                                                                <h4 className="mb-10">Venue</h4>
                                                                <p>{this.state.Event.EventLocation}</p>
                                                                {/* <p>{this.state.Event.StartDate}-{this.props.EndDate}</p> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                            <div className="project-info-box mb-45">
                                                                <h4 className="mb-10">Time</h4>
                                                                <p>{this.state.Event.StartTime}-<br />{this.props.EndTime}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                            <div className="project-info-box mb-45">
                                                                <h4 className="mb-10">Organizer</h4>
                                                                <p>{this.state.Event.ContactPersonDetails}, {this.state.Event.ContactPersonMobileNo}, {this.state.Event.ContactPersonEmailid}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <p className="text-justify">{this.state.Event.EventContent}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 pb-75" style={{ textAlign: "center" }}>
                            <div dangerouslySetInnerHTML={{ __html: this.state.Event.GoogleMabLink }} />
                        </div> */}
                    </div>
                </section>

                <section class="contact-page-map pt-40">
                    <div class="map-box">
                        <div dangerouslySetInnerHTML={{ __html: this.state.Event.GoogleMabLink }} />
                    </div>
                </section>
            </>
        );
    }
}

