import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

export default class PageContent extends Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
                    </Col>
                </Row>
            </Container>
        );
    }
}