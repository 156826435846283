import React, { Component } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

var Api_url = process.env.REACT_APP_API_URL;
var defaultImage = Api_url + `/assets/images/white-user.png`;
console.log('defaultImage=' + defaultImage);

export default class TeamPage extends Component {
    render() {
        return (
            <>
                <section className="farmers-team_page pt-80 pb-130">
                    <div className="container">
                        {/* <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-10">
                                <div className="section-title text-center mb-50 wow fadeInUp">
                                    <span className="sub-title">Our Members</span>
                                    <h2>We Have Lot’s Of Experience
                                        Team Members</h2>
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            {this.props.members.map((member, index) => (
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={index}>
                                    <div className="team-member_one text-center mb-60 wow fadeInUp">
                                        <div className="member-img">
                                        <LazyLoadImage effect="blur" src={member.ProfilePic} alt="" width="200px" height="200px" placeholderSrc={member.ProfilePic} />
                                        </div>
                                        <div className="member-info">
                                            <h3 className="title"><Link to="/">{member.MemberName}</Link></h3>
                                            <p className="position">{member.Designation}</p>
                                            <ul className="social-link">
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                                                <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}