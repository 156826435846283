import axios from "axios";
import React, { Component } from "react";
import LoadingContent from "../LoadingContent";

const records = [];

var Api_url = process.env.REACT_APP_API_URL;

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    },
};

export default class PaymentContent extends Component {
    constructor() {
        super();
        this.state = {
            Records: records,
            LoadingFlag: false,
            TotalRecords: 0,
        }
    };

    async getAllRecords() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "jci_india_website",
            SettingsName: "payment_links",
        };
        this.setState({ LoadingFlag: true });
        console.log("postData=" + JSON.stringify(postData));
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = result.length;
                console.log("n1=" + n1);

                this.setState({ LoadingFlag: false, Records: result, TotalRecords: n1 });

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        window.scrollTo(0, 0);
        this.getAllRecords();
    }

    render() {
        return (
            <>
                <section class="shaop-page pt-130 pb-70">
                    <div class="container">
                        {this.state.LoadingFlag ? <LoadingContent /> : ''}
                        <div class="products-wrapper">
                            <div class="row">
                                {this.state.Records.map((item, index) => (
                                    <div>

                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <div class="single-product-item mb-60 wow fadeInUp" style={{ width: '350px', height: '350px' }} key={index}>
                                                <div class="product-info">
                                                    <h4>
                                                        <a href={item.SettingsValuesContent} target="_blank" rel="noopener noreferrer">
                                                            {item.SettingsValuesTitle}
                                                        </a>
                                                    </h4>
                                                    <br/>
                                                    <p>{item.SettingsValuesDescription}</p>
                                                    <br/>
                                                    <div class="cart-button">
                                                        <a href={item.SettingsValuesContent} class="main-btn btn-yellow" target="_blank" rel="noopener noreferrer">Pay Now</a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {/* <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="single-product-item mb-60 wow fadeInUp">
                                        <div class="product-img">
                                            <img src="/assets/images/products/img-3.png" alt="" />
                                            <div class="pc-btn">Food</div>
                                            <div class="cart-button"><a href="products.html" class="main-btn btn-yellow">Add to cart</a></div>
                                        </div>
                                        <div class="product-info">
                                            <ul class="ratings">
                                                <li><i class="fas fa-star"></i></li>
                                                <li><i class="fas fa-star"></i></li>
                                                <li><i class="fas fa-star"></i></li>
                                                <li><i class="fas fa-star"></i></li>
                                                <li><i class="fas fa-star"></i></li>
                                            </ul>
                                            <h3 class="title"><a href="product-details.html">Organice Delicious
                                                Pomegranate</a></h3>
                                            <span class="price"><span class="curreny">$</span>53.56</span>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}