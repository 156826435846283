import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";

const bgImage = process.env.PUBLIC_URL + "/assets/images/bg/jci_backdrop.jpg";
console.log("bgImage = ", bgImage);

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
}

export default class WhyChooseUs extends Component {
    constructor() {
        super();
        this.state = {
            PresidentImage: {},
            PresidentMessage: {},
            PresidentLogo: {},
            Total1: 0,
            LoadingFlag1: false,
            Total2: 0,
            LoadingFlag2: false,
            Total3: 0,
            LoadingFlag3: false,
        };
    }
    async getAllRecords1() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "jci_india_website",
            SettingsName: "np_full_image",
        };
        this.setState({ LoadingFlag1: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag1: false, PresidentImage: result, Total1: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords2() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "jci_india_website",
            SettingsName: "np_message",
        };
        this.setState({ LoadingFlag2: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag2: false, PresidentMessage: result, Total2: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords3() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "jci_india_website",
            SettingsName: "np_logo",
        };
        this.setState({ LoadingFlag3: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag3: false, PresidentLogo: result, Total3: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        this.getAllRecords1();
        this.getAllRecords2();
        this.getAllRecords3();
    }

    render() {
        const PresidentMessage = this.state.PresidentMessage.SettingsValuesContent;

        return (
            <>
                {/* <!--====== Start Why Choose Section ======--> */}
                <section className="why-choose-one p-r z-1 ">
                    <img className="why-choose-one_img" style={{ backgroundImage: `url(${bgImage})` }} alt="" />
                    <div className="container pt-100 pb-100">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="choose-one_img-box p-r mb-40 wow fadeInLeft">
                                    {this.state.Total1 > 0 ? <img src={this.state.PresidentImage.FilePath} className="choose-img_one" width={300} height={600} alt="" /> : ""}
                                    {/* {this.state.Total3 > 0 ? <img src={this.state.PresidentLogo.FilePath} className="choose-img_two" width={200} height={200} alt="" /> : ""} */}
                                    {/* <img src="/assets/images/choose/img-1.jpg" className="choose-img_one" alt="" /> */}
                                    {/* <img src="/assets/images/choose/img-2.jpg" className="choose-img_two" alt="" /> */}
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="choose-one_content-box mb-40">
                                    <div className="section-title section-title-left mb-40 wow fadeInDown">
                                        <span className="sub-title">NATIONAL PRESIDENT CORNER</span>
                                    </div>
                                    <div className="choose-item-list wow fadeInUp">
                                        <div className="single-choose-item mb-30">
                                            <div className="text">
                                                <h5 style={{ fontFamily: "Open Sans bold" }}>{this.state.PresidentImage.SettingsValuesTitle}</h5>
                                                {/* <h6 style={{ color:"black" }}> */}
                                                    <div className="text-justify" style={{ overflow: "hidden", height: "19em", fontFamily: "Merriweather", }} dangerouslySetInnerHTML={{ __html: PresidentMessage }} />
                                                {/* </h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="choose-button wow fadeInUp">
                                        <Link to={"about-np-corner"} className="main-btn bordered-btn bordered-yellow" style={{fontFamily: "Lora" }} >Know More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--====== End Why Choose Section ======--> */}
            </>
        );
    }
}