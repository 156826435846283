import React, { Component } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCoffee, faUmbrella } from '@fortawesome/free-solid-svg-icons';

// const icon1 = <FontAwesomeIcon icon={faCoffee} />;
// const icon2 = <FontAwesomeIcon icon={faUmbrella} />;
export default class FeaturesSection extends Component {
    render() {
        return (
            <>
                <section className="features-section">
                    <div className="container-1350">
                        <div className="features-wrap-one wow fadeInUp">
                            <div className="row justify-content-center">
                                <div className="col-xl-4 col-md-6 col-sm-12">
                                    <div className="features-item d-flex mb-30">
                                        {/* <div className="fill-number">01</div> */}
                                        <div className="icon">
                                            <img src="/assets/fonts/flaticon/png/1.png" style={{ width: "40px", height: "40px" }} alt=""></img>
                                        </div>
                                        <div className="text">
                                            <h5>Leadership Organization</h5>
                                            <p>We develop leaders for a better tomorrow</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6 col-sm-12">
                                    <div className="features-item d-flex mb-30">
                                        {/* <div className="fill-number">02</div> */}
                                        <div className="icon">
                                        <img src="/assets/fonts/flaticon/png/2.png" style={{ width: "40px", height: "40px" }} alt=""></img>
                                        </div>
                                        <div className="text">
                                            <h5>Sustainable Solutions</h5>
                                            <p>Our members provide sustainable solutions in their region</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6 col-sm-12">
                                    <div className="features-item d-flex mb-30">
                                        {/* <div className="fill-number">01</div> */}
                                        <div className="icon">
                                        <img src="/assets/fonts/flaticon/png/3.png" style={{ width: "40px", height: "40px" }} alt=""></img>
                                        </div>
                                        <div className="text">
                                            <h5>Young active leaders</h5>
                                            <p>We are the largest organization with young and active leaders</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
