import React, { Component } from "react";
import ZoneEventPage from "../../components/event/ZoneEventPage";
import PageBanner from "../../components/service/PageBanner";
import { Helmet } from "react-helmet";

export default class ZoneEvent extends Component {
    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>Zone Event | JCI India | Be Better</title>
                    {/* <meta name='description' content='About-JCI India' /> */}
                </Helmet>
                <PageBanner bannerDetails={{ bannerTitle: "Zone Event", bannerActive: "Event" }} />
                <ZoneEventPage />
            </>
        );
    }
}