import React, { Component } from "react";
import NPCornerSection from "../../components/about-us/NPCornerSection";
import PageBanner from "../../components/service/PageBanner";

import { Helmet } from "react-helmet";

export default class NPCorner extends Component {

    componentDidMount() {
        console.log("componentDidMount called ");
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>NP Corner | JCI India | Be Better</title>
                    {/* <meta name='description' content='About-JCI India' /> */}
                </Helmet>
                <PageBanner bannerDetails={{ bannerTitle: "NP Corner", bannerActive: "About US" }} />
                <NPCornerSection />
            </>
        );
    }
}