import axios from "axios";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import Lightbox from "react-image-lightbox";
import 'swiper/css';
import { Swiper, SwiperSlide } from "swiper/react";

const bgImage = process.env.PUBLIC_URL + "/assets/images/bg/jci_india_members.jpg";
console.log("bgImage = ", bgImage);

const MyLoader = (props) => (
    <div style={{ width: "100%", height: "100%" }}>
        <Row>
            <Col xl={12} lg={12} >
                <ContentLoader
                    speed={2}
                    viewBox="0 0 400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                >
                    <rect x="25" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="100" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="175" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="250" y="15" rx="5" ry="5" width="50" height="50" />
                </ContentLoader>
            </Col>
        </Row>
    </div >
)

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

export default class GallerySlider extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            Slides: [],
            LoadingFlag: false,
            TotalSlides: 0,
            FilePath: '',
        };
    }

    async getAllRecords() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "jci_india_website",
            SettingsName: "home_gallery",
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = result.length;
                console.log("n1=" + n1);

                this.setState({ LoadingFlag: false, Slides: result, TotalSlides: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        this.getAllRecords();
    }
    render() {

        const { FilePath, isOpen } = this.state;
        return (
            <>
                <section className="gallery-section p-r z-1">
                    <img className="gallery-section_img" style={{ backgroundImage: `url(${bgImage})`}} alt="" />
                    <div className="container pt-100 pb-100">
                        {this.state.LoadingFlag ? <MyLoader /> : ""}
                        <div className="row align-items-end">
                            <div className="col-xl-7 col-lg-10">
                                <div className="section-title section-title-left mb-60 wow fadeInLeft">
                                    <span className="sub-title">Gallery</span>
                                    <h3 style={{ fontFamily: "Open Sans bold" }}>Our memorable moments</h3>
                                </div>
                            </div>
                        </div>
                        <Container>
                            <Row className="align-items-center mt-3">
                                <Col>
                                    {this.state.LoadingFlag ? <MyLoader /> : ""}
                                    <Swiper
                                        navigation
                                        spaceBetween={80}
                                        slidesPerView={3}
                                        autoplay
                                        loop
                                        onSlideChange={() => console.log("photo slide change")}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                            },
                                            576: {
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                            },
                                            992: {
                                                slidesPerView: 3,
                                            }
                                        }}
                                    >
                                        {this.state.Slides.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                {isOpen && (
                                                    <Lightbox
                                                        mainSrc={FilePath}
                                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                                    />
                                                )}
                                                {console.log("isOpen = ", isOpen)}
                                                <div>
                                                    <img src={item.FilePath} onClick={() => this.setState({ isOpen: true, FilePath: item.FilePath })} alt="" style={{ width: 330, height: 200, borderRadius: 15, objectFit: 'cover' }} />
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
            </>
        );
    }
}