import React, { Component } from "react";
import NationalEventPage from "../../components/event/NationalEventPage";
import PageBanner from "../../components/service/PageBanner";
import { Helmet } from "react-helmet";

export default class NationalEvent extends Component {
    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
            <Helmet>
                <title>National Event | JCI India | Be Better</title>
                {/* <meta name='description' content='About-JCI India' /> */}
            </Helmet>
                <PageBanner bannerDetails={{ bannerTitle: "National Event", bannerActive: "Event" }} />
                <NationalEventPage/>
            </>
        );
    }
}