import React, { Component } from "react";
import DownloadSection from "../components/download/DownloadSection";
import PageBanner from "../components/service/PageBanner";
import { Helmet } from "react-helmet";

export default class Download extends Component {
    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
            <Helmet>
                <title>Download | JCI India | Be Better</title>
                {/* <meta name='description' content='About-JCI India' /> */}
            </Helmet>
                <PageBanner bannerDetails={{ bannerTitle: "Download", bannerActive: "Download" }} />
                <DownloadSection FormType="presidential_tool_kit" pageTitle="Presidential Tool Kit" />
                <DownloadSection FormType="plan_of_action" pageTitle="Plan of action" />
                <DownloadSection FormType="management" pageTitle="Management" />
                <DownloadSection FormType="training" pageTitle="Training" />
                <DownloadSection FormType="program" pageTitle="Program" />
                <DownloadSection FormType="business" pageTitle="Business" />
                <DownloadSection FormType="growth_and_development" pageTitle="Growth and Development" />
                <DownloadSection FormType="jci_india_foundation" pageTitle="JCI India Foundation" />
                <DownloadSection FormType="challenge_issue" pageTitle="Challenge Issue" />
                <DownloadSection FormType="active_citizen" pageTitle="Active Citizen" />
                <DownloadSection FormType="constitution_&_policy_manual" pageTitle="Constitution & Policy Manual" />
                <DownloadSection FormType="others" pageTitle="Others" />
            </>
        );
    }
}
