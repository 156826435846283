import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Button, Modal } from "react-bootstrap";
// import ModalVideo from "react-modal-video";
import ReactPlayer from 'react-player';
// import 'react-modal-video/css';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';

const bgImage = process.env.PUBLIC_URL + "/assets/images/bg/jci_members.jpg";
console.log("bgImage = ", bgImage);

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

export default class ProjectsSection extends Component {
    constructor() {
        super();
        this.state = {
            Records: [],
            LoadingFlag: false,
            Modal: false,
            VideoId: "",
            VideoPath: '',
            TotalRecords: 0,
        }
    };

    getAllRecords = async () => {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "jci_india_website",
            SettingsName: "home_video_gallery",
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = result.length;
                console.log("n1=" + n1);

                this.setState({ LoadingFlag: false, Records: result, TotalRecords: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        this.getAllRecords();
    }


    openModal(id) {
        console.log("openModal called ");
        console.log("id=" + id);

        if (id !== "") {
            var VideoPath = 'https://www.youtube.com/watch?v=' + id;
            this.setState({ VideoId: id });
            this.setState({ VideoPath: VideoPath });
            this.setState({ Modal: true });
            console.log("VideoId=" + this.state.VideoId);
        }
    }
    closeModal() {
        console.log("closeModal called ");

        this.setState({ VideoId: '' });
        this.setState({ VideoPath: '' });
        this.setState({ Modal: false });
        console.log("VideoId=" + this.state.VideoId);
    }
    render() {
        return (
            <>
                {/* <ModalVideo
                    channel="youtube"
                    autoplay
                    isOpen={this.state.Modal}
                    videoId={this.state.VideoId}
                    onClose={() => this.setState({ Modal: false })}
                /> */}
                <Modal size="lg" centered show={this.state.Modal} onHide={this.closeModal.bind(this)}>
                    <Modal.Body>
                        <ReactPlayer width="100%" controls url={this.state.VideoPath} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeModal.bind(this)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <section className="projects-section p-r z-1">
                    <img className="projects-section_img" style={{ backgroundImage: `url(${bgImage})`}} alt="" />
                    {/* style={{backgroundImage: `url(${bgImage1})`}}> */}
                    <div className="container pt-100 pb-100">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12">
                                <div className="section-title section-title-left mb-60 wow fadeInLeft">
                                    <span className="sub-title">Video Gallery</span>
                                    <h3 style={{ fontFamily: "Open Sans bold" }}>Go through our video below</h3>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-3">
                                <div className="project-arrows float-md-right wow fadeInRight"></div>
                            </div>
                        </div>
                        <Row className="align-items-center mt-3">
                            <Col>
                                <Swiper
                                    navigation
                                    spaceBetween={100}
                                    slidesPerView={3}
                                    autoplay
                                    loop
                                    onSlideChange={() => console.log("slide change")}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                        },
                                        576: {
                                            slidesPerView: 1,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        992: {
                                            slidesPerView: 3,
                                        }
                                    }}
                                >
                                    {this.state.LoadingFlag
                                        ? ""
                                        : this.state.TotalRecords > 0
                                            ?
                                            this.state.Records.map((item, index) => (
                                                <SwiperSlide key={index}>
                                                    <img src={"http://img.youtube.com/vi/" + item.SettingsValuesDescription + "/mqdefault.jpg"} width="380" height="250" alt={item.SettingsValuesTitle} />

                                                    <div className=" text-center" style={{ position: "absolute", width: "380px", marginTop: "-150px" }}>
                                                        <div className="video-card__btn-block">

                                                            <span
                                                                onClick={this.openModal.bind(this, item.SettingsValuesDescription)}
                                                                onKeyDown={this.openModal.bind(this, item.SettingsValuesDescription)}
                                                                className="video-popup video-card__btn"
                                                                role="button"
                                                                tabIndex={0}
                                                            >
                                                                <i className="fa fa-play"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )) : ""
                                    }
                                </Swiper>
                            </Col>
                        </Row>
                    </div>
                </section>
            </>
        );
    }
}