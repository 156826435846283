import HeroSection from '../components/home/HeroSection';
import FeaturesSection from '../components/home/FeaturesSection';
import AboutSection from '../components/home/AboutSection';
// import ServiceOne from '../components/home/ServiceOne';
import ProjectsSection from '../components/home/ProjectsSection';
import PopularService from '../components/home/PopularService';
// import OfferSection from '../components/home/OfferSection';
// import TestimonialOne from '../components/home/TestimonialOne';
// import ContactOne from '../components/home/ContactOne';
import BlogSection from '../components/home/BlogSection';
// import PartnerSection from '../components/home/PartnerSection';
import { useEffect } from 'react';
import WhatWeOffers from '../components/home/WhatWeOffers';
import WhyChooseUs from '../components/home/WhyChooseUs';
import GallerySlider from '../components/home/GallerySlider';
import BeBetter from '../components/home/BeBetter';

import Helmet from 'react-helmet';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Helmet>
        <title>JCI India | Be Better | Be Better</title>
        {/* <meta name='description' content='Home-JCI India' /> */}
      </Helmet>

      <HeroSection /> {/* Home Slider */}
      <FeaturesSection /> {/* yellowbox attach with slider */}
      <AboutSection /> {/* What is JCI */}
      <WhatWeOffers /> {/* Why join JCI */}
      <WhyChooseUs /> {/* Our Leader */}
      <PopularService /> {/* What we do */}
      <ProjectsSection /> {/* Video Gallery */}
      <BlogSection />
      <GallerySlider />
      <BeBetter />
      {/* <ServiceOne /> */}
      {/* <OfferSection /> */}
      {/* <TestimonialOne /> */}
      {/* <ContactOne /> */}
      {/* <PartnerSection /> */}
    </div>
  );
}

export default App;
