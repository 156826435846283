import React, { Component } from 'react';

const bgImage1 = process.env.PUBLIC_URL + "/assets/images/bg/pattern2.jpg";
console.log("bgImage1 = ", bgImage1);

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

var Image1 = Api_url + `/assets/website/home_bg_about_us.jpg`;
console.log("Image1 = ", Image1);

export default class AboutSection extends Component {
    render() {
        return (
            <>
                <section className="about-section p-r z-1 pt-130 pb-80">
                    {/* style={{ backgroundImage: `url(${bgImage1})`, }}> */}
                    <div className="container pb-100">
                        <div className="row align-items-center">
                            <div className="col-xl-5 col-lg-6">
                                <div className="about-one_content-box mb-50">
                                    <div className="section-title section-title-left mb-30 wow fadeInUp">
                                        <span className="sub-title" style={{ fontFamily: "Poppins" }}>What is JCI</span>
                                        <h5 style={{ fontFamily: "Open Sans bold" }}>Developing Leaders for a Changing World</h5>
                                    </div>
                                    <div className="quote-text mb-35 wow fadeInDown" data-wow-delay=".3s">
                                        <p className="text-justify" style={{ fontFamily: "Merriweather" }}>Junior Chamber International (JCI) is a worldwide federation of young leaders and entrepreneurs with nearly five lakh active members and millions of alumni spread across more than 115 countries. We are young active citizens all over the world, living, communicating, taking action and creating impact in our communities.</p>
                                    </div>
                                    <div className="tab-content-box wow fadeInUp">
                                        <ul className="nav nav-tabs mb-20">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#mission" style={{ fontFamily: "Lora" }}>Our Mission</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#vision" style={{ fontFamily: "Lora" }}>Our Vision</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content mb-30">
                                            <div className="tab-pane fade show active" id="mission">
                                                <div className="content-box">
                                                    <p style={{ fontFamily: "Merriweather" }}>To provide leadership development opportunities that empower young people to create positive change.</p>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="vision">
                                                <div className="content-box">
                                                    <p style={{ fontFamily: "Merriweather" }}>To be the foremost global network of young leaders.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="avatar-box d-flex align-items-center">
                                            <div className="thumb">
                                                <img src="assets/images/user-1.jpg" alt="Admin Thumb" />
                                            </div>
                                            <div className="content">
                                                <img src="assets/images/sign-1.png" alt="Sign" />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-6">
                                <div className="about-one_image-box p-r mb-50 pl-100">
                                    {/* <div className="about-img_one wow fadeInLeft">
                                        <img src="assets/images/about/img-1.jpg" alt="About Image1" />
                                    </div> */}
                                    <div className="about-img_two wow fadeInRight">
                                        <img src={Image1} alt="Jci india" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}