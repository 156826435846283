import React, { Component } from "react";
import AboutJCICreed from "../../components/about-us/AboutJCICreed";
import AboutJCISection from "../../components/about-us/AboutJCISection";
import AboutTestimoinal from "../../components/about-us/AboutTestimonial";
import PageBanner from "../../components/service/PageBanner";

import Helmet from 'react-helmet';

export default class AboutJCI extends Component {
    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>About JCI | JCI India | Be Better</title>
                    {/* <meta name='description' content='About-JCI India' /> */}
                </Helmet>
                <PageBanner bannerDetails={{ bannerTitle: "About JCI", bannerActive: "About US" }} />
                <AboutJCISection />
                <AboutTestimoinal />
                <AboutJCICreed />
            </>
        );
    }
}
