import React, { Component } from "react";
import axios from "axios";

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const bgImage = process.env.PUBLIC_URL + "/assets/images/bg/about-bg3.jpg";
console.log("bgImage = ", bgImage);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
}

export default class NPCornerSection extends Component {
    constructor() {
        super();
        this.state = {
            PresidentImage: {},
            PresidentMessage: {},
            PresidentLogo: {},
            Total1: 0,
            LoadingFlag1: false,
            Total2: 0,
            LoadingFlag2: false,
            Total3: 0,
            LoadingFlag3: false,
        };
    }
    async getAllRecords1() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "jci_india_website",
            SettingsName: "np_full_image",
        };
        this.setState({ LoadingFlag1: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag1: false, PresidentImage: result, Total1: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords2() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "jci_india_website",
            SettingsName: "np_message",
        };
        this.setState({ LoadingFlag2: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag2: false, PresidentMessage: result, Total2: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getAllRecords3() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "jci_india_website",
            SettingsName: "np_logo",
        };
        this.setState({ LoadingFlag3: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = Object.keys(result).length;
                console.log({ n1 });
                console.log({ result });

                this.setState({ LoadingFlag3: false, PresidentLogo: result, Total3: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        this.getAllRecords1();
        this.getAllRecords2();
        this.getAllRecords3();
    }
    render() {
        return (
            <>
                <section className="about-section p-r z-1">
                     {/* style={{ position: "relative", backgroundColor: "white" }}> */}
                    {/* <img style={{ backgroundImage: `url(${bgImage})`, opacity: 0.5, objectFit: 'cover', backgroundPosition: "bottom", backgroundRepeat: "no-repeat", width: "100%", height: "100%", position: "absolute", }} alt="" /> */}
                    <div className="container" style={{ maxWidth: "80%" }} >
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                {/* NP Image */}
                                <div className="about-four_content-box mb-30 text-center">
                                    <div className="section-title section-title-left wow fadeInUp mt-30 text-center">
                                        <span className="sub-title mb-30">National President</span>
                                    </div>
                                    {this.state.Total1 > 0 ? <img src={this.state.PresidentImage.FilePath} style={{ width: "400px", height: "600px" }} alt="" /> : ""}
                                </div>
                                {/* NP Logo */}
                                <div className="about-four_content-box  mb-30 text-center">
                                    <div className="section-title section-title-left wow fadeInUp mt-30 text-center">
                                        <span className="sub-title mb-30">National President Logo</span>
                                    </div>
                                    {this.state.Total3 > 0 ? <img src={this.state.PresidentLogo.FilePath} width={200} height={200} alt="" /> : ""}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="about-four_content-box mb-30">
                                    <div className="section-title section-title-left wow fadeInUp mt-30 text-center">
                                        <span className="sub-title mb-20">National President Message</span>
                                    </div>
                                    <h4 className="mt-10 mb-20">{this.state.PresidentImage.SettingsValuesTitle}</h4>
                                    {/* <h5>
                                    // style="color:black"
                                        <p style={{color:"black"}}> */}
                                    <div className="text-justify" style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: this.state.PresidentMessage.SettingsValuesContent }}></div>
                                    {/* </p>
                                    </h5> */}
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}