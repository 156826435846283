import React, { Component } from "react";
import PaymentContent from "../components/payment/PaymentContent";
import PageBanner from "../components/service/PageBanner";
import { Helmet } from "react-helmet";

export default class OnlinePayment extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>JCI India | Be Better | Be Better</title>
                    {/* <meta name='description' content='Home-JCI India' /> */}
                </Helmet>
                <PageBanner bannerDetails={{ bannerTitle: "Online Payment", bannerActive: "Online Payment" }} />
                <PaymentContent />
            </>
        );
    }
}