import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

export default class DownloadList extends Component {
    render() {
        return (
            <>
                <Row>
                    {this.props.AllData.map(({ JciFormsName, JciFormsPath }, index) => (
                        <Col xl={3} lg={4} sm={6} key={index}>
                            <a href={JciFormsPath} target="_blank" rel="noreferrer">
                                <i className="far fa-download"></i> {JciFormsName}
                            </a>
                        </Col>
                    ))}
                </Row>

            </>
        );
    }
}
