import React, { Component } from "react";

export default class AboutJCICreed extends Component {

    render() {
        return (
            <>
                <section className="about-section p-r z-1 pt-80 pb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-four_content-box mb-10">
                                    <div className="section-title section-title-left wow fadeInUp">
                                        <span className="sub-title">JCI Creed</span>
                                        <h2>We Believe</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-four_content-box mb-10">
                                    <div className="choose-item-list wow fadeInDown">
                                        <div className="single-choose-item mb-30">
                                            <div className="text">
                                                <h5> That faith in god gives meaning and purpose to human life, </h5>
                                            </div>
                                        </div>
                                        <div className="single-choose-item mb-30">
                                            <div className="text">
                                                <h5> That the brotherhood of man transcends the sovereignty of nations, </h5>
                                            </div>
                                        </div>
                                        <div className="single-choose-item mb-30">
                                            <div className="text">
                                                <h5> That economic justice can best be won by free men through free enterprise, </h5>
                                            </div>
                                        </div>
                                        <div className="single-choose-item mb-30">
                                            <div className="text">
                                                <h5> That government should be of laws rather than of men, </h5>
                                            </div>
                                        </div>
                                        <div className="single-choose-item mb-30">
                                            <div className="text">
                                                <h5> That earth’s great treasure lies in human personality, </h5>
                                            </div>
                                        </div>
                                        <div className="single-choose-item mb-30">
                                            <div className="text">
                                                <h5> And that service to humanity is the best work of life. </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}