import axios from "axios";
import React, { Component } from "react"
import { Button, Card, Container} from "react-bootstrap";
import DownloadList from "./DownloadList";

var Api_url = process.env.REACT_APP_API_URL;
var url = Api_url + `/api/jci-forms/downloads_list`;
console.log('API_URL=' + Api_url + ', url=' + url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class DownloadSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PageTitle: "",
            Records: [],
            LoadingFlag: false,
            TotalRecords: 0,
            FormType: '',
        };
    }
    async fetchData() {
        console.log("fetchData called ");
        var FormType = this.props.FormType;
        console.log({ FormType });
        var postData = {
            UserInterface: 1,
            "FormType": FormType
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ LoadingFlag: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var records = res.data.records;
                var result = records.TableData;
                console.log({ result });
                var options = records.TableOptions;
                console.log({ options });
                var n1 = result.length;
                console.log({ n1 });
                this.setState({ LoadingFlag: false, TotalRecords: n1, Records: result, PageTitle: options.PageTitle });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        this.fetchData();
    }
    render() {
        return (
            <>
                <Container>
                    <Card.Body>
                        <div className="sidebar-widget-area">
                            <div className="widget download-widget">
                                <Card.Title>
                                    {this.state.PageTitle} { } { }
                                    <Button variant="outline-primary" size="sm" onClick={this.fetchData}>Refresh</Button>
                                </Card.Title>
                                <Card.Text>
                                    {
                                        this.state.TotalRecords > 0 ? <DownloadList AllData={this.state.Records} /> : 'NA'
                                    }
                                </Card.Text>
                            </div>
                        </div>
                        <br />
                    </Card.Body>
                </Container>
            </>
        );
    }
}
