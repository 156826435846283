import React, { Component } from "react";

export default class BeBetter extends Component {
    render() {
        return (
            <>
                <section className="fun-fact-one pt-80 pb-100">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-xl-7 col-lg-10">
                                <div className="section-title section-title-left mb-60 wow fadeInLeft">
                                    <span className="sub-title">Our Tagline</span>
                                </div>
                            </div>
                        </div>
                        <div className="big-text mb-75 wow fadeInUp"><h2 style={{fontFamily:"Merriweather"}}>Developing Leaders for a changing world.</h2></div>
                    </div>
                </section>

            </>
        )
    }
}