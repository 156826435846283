import React, { Component } from "react";

const bgImage1 = process.env.PUBLIC_URL + "/assets/images/bg/pattern4.png";
console.log("bgImage1 = ",bgImage1);

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

var Image1 = Api_url + `/assets/website/home_bg_skills.jpg`;
console.log("Image1 = ", Image1);

export default class PopularService extends Component {
    render() {
        return (
            <>
                <section className="popular-service p-r z-1 pt-60">
                     {/* style={{backgroundImage: `url(${bgImage1})`}}> */}
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="section-title text-center mb-50 wow fadeInDown">
                                    <span className="sub-title">What we do</span>
                                    <h4 style={{fontFamily: "Open Sans bold"}}>We develop skills, knowledge and leadership abilities of young citizens who go on to provide sustainable solutions that creates a positive impact</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="single-service-item mb-50 wow fadeInUp">
                                    <div className="icon">
                                        <img src="/assets/fonts/flaticon/png/21.png" style={{ width: "50px", height: "50px" }} alt=""></img>
                                    </div>
                                    <div className="text mt-30">
                                        <h5>Individual growth</h5>
                                    </div>
                                </div>
                                <div className="single-service-item mb-50 wow fadeInDown">
                                    <div className="icon">
                                        <img src="/assets/fonts/flaticon/png/22.png" style={{ width: "50px", height: "50px" }} alt=""></img>
                                    </div>
                                    <div className="text mt-15">
                                        <h5>Business & Entrepreneurship</h5>
                                    </div>
                                </div>
                                <div className="single-service-item mb-50 wow fadeInUp">
                                    <div className="icon">
                                        <img src="/assets/fonts/flaticon/png/23.png" style={{ width: "50px", height: "50px" }} alt=""></img>
                                    </div>
                                    <div className="text mt-30">
                                        <h5>Community action</h5>
                                    </div>
                                </div>
                                <div className="single-service-item mb-50 wow fadeInUp">
                                    <div className="icon">
                                        <img src="/assets/fonts/flaticon/png/24.png" style={{ width: "50px", height: "50px" }} alt=""></img>
                                    </div>
                                    <div className="text mt-30">
                                        <h5>International cooperation</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="img-holder mb-50 wow fadeInDown">
                                    <img src={Image1} alt="Jci India" />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="single-service-item mb-50 card-rtl wow fadeInDown">
                                    <div className="icon">
                                        <img src="/assets/fonts/flaticon/png/31.png" style={{ width: "50px", height: "50px" }} alt=""></img>
                                    </div>
                                    <div className="text mt-30">
                                        <h5>Analyse</h5>
                                    </div>
                                </div>
                                <div className="single-service-item mb-50 card-rtl fadeInUp">
                                    <div className="icon">
                                        <img src="/assets/fonts/flaticon/png/32.png" style={{ width: "50px", height: "50px" }} alt=""></img>
                                    </div>
                                    <div className="text mt-30">
                                        <h5>Develop</h5>
                                    </div>
                                </div>
                                <div className="single-service-item mb-50 card-rtl wow fadeInDown">
                                    <div className="icon">
                                        <img src="/assets/fonts/flaticon/png/33.png" style={{ width: "50px", height: "50px" }} alt=""></img>
                                    </div>
                                    <div className="text mt-30">
                                        <h5>Execute</h5>
                                    </div>
                                </div>
                                <div className="single-service-item mb-50 card-rtl wow fadeInDown">
                                    <div className="icon">
                                        <img src="/assets/fonts/flaticon/png/34.png" style={{ width: "50px", height: "50px" }} alt=""></img>
                                    </div>
                                    <div className="text mt-30">
                                        <h5>Review</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}