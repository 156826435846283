import axios from "axios";
import React, { Component } from "react";
import LoadingContent from "../../components/LoadingContent";
import PageContent from "../../components/content/PageContent";
import ContentBanner from "../../components/content/ContentBanner";

var Api_url = process.env.REACT_APP_API_URL;
var url = Api_url + `/api/wm/yearwise/team/members/lists`;
console.log('API_URL=' + Api_url + ', url=' + url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class TermsOfService extends Component {
    constructor() {
        super();
        this.state = {
            Result: "",
            LoadingFlag: false,
            TotalRecords: 0,
        }
    };

    async getAllRecords() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/pages/show`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            PagesId: 4,
        };
        this.setState({ LoadingFlag: true });
        console.log("postData=" + JSON.stringify(postData));
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var flag = res.data.flag;
                console.log("flag=" + flag);
                var result = '';

                if (flag) {
                    var records = res.data.records;
                    result = records.PagesContent;
                } else {
                    result = "<p>NA</p>";
                }
                console.log("result=" + result);

                this.setState({ LoadingFlag: false, Result: result });

            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        window.scrollTo(0, 0);
        this.getAllRecords();
    }
    render() {
        return (
            <>
                <ContentBanner bannerDetails={{ bannerTitle: "Terms Of Service", bannerActive: "Terms Of Service" }} />
                {this.state.LoadingFlag ? <LoadingContent /> : <PageContent content={this.state.Result} />
                }
            </>
        );
    }
}