import axios from "axios";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";
import 'swiper/css';
import { Swiper, SwiperSlide } from "swiper/react";

const bgImage1 = process.env.PUBLIC_URL + "/assets/images/bg/pattern3.jpg";
console.log("bgImage1 = ",bgImage1);

const MyLoader = (props) => (
    <div style={{ width: "100%", height: "100%" }}>
        <Row>
            <Col xl={12} lg={12} >
                <ContentLoader
                    speed={2}
                    viewBox="0 0 400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                >
                    <rect x="25" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="100" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="175" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="250" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="325" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="25" y="90" rx="5" ry="5" width="50" height="50" />
                    <rect x="100" y="90" rx="5" ry="5" width="50" height="50" />
                    <rect x="175" y="90" rx="5" ry="5" width="50" height="50" />
                    <rect x="250" y="90" rx="5" ry="5" width="50" height="50" />
                    <rect x="325" y="90" rx="5" ry="5" width="50" height="50" />
                </ContentLoader>
            </Col>
        </Row>
    </div >
)

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

export default class BlogSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Records: [],
            LoadingFlag: false,
            TotalRecords: 0,
        };
    }
    async getAllRecords() {
        console.log("getAllRecords called");
        var url = Api_url + `/api/programs/events/lists`;
        console.log("url = ", url);
        var postData = {
            UserInterface: 4,
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });

        await axios.post(url, postData, axiosConfig)
            .then(res => {
                console.log("Response Received : ", res);
                console.log("res : ", JSON.stringify(res));

                this.setState({ LoadingFlag: false });

                var flag = res.data.flag;
                var records = res.data.records;
                console.log({ records });

                if (flag) {

                    var result = records.EventsData;
                    var n1 = result.length;
                    console.log("n1=" + n1);

                    this.setState({ Records: result, TotalRecords: n1 });
                } else {
                    this.setState({ hasMore: false });
                }
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    }
    componentDidMount() {
        console.log("componentDidMount called");
        this.getAllRecords();
    }
    render() {

        if (this.state.TotalRecords > 0) {
            return (
                <>
                    <section className="blog-section p-r z-1 pt-100 pb-100">
                         {/* style={{backgroundImage: `url(${bgImage1})` }}> */}
                        <div className="container">
                            {this.state.LoadingFlag ? <MyLoader /> : ""}
                            <div className="row align-items-end">
                                <div className="col-xl-7 col-lg-10">
                                    <div className="section-title section-title-left mb-60 wow fadeInLeft">
                                        <span className="sub-title">Featured Programs</span>
                                        <h3 style={{fontFamily: "Open Sans bold"}}>Checkout our upcoming programs</h3>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="button-box float-lg-right mb-60 wow fadeInRight">
                                        <div className="main-btn bordered-btn bordered-yellow">
                                            <Link to="program/national-program" style={{fontFamily:"Lora" }}>All Programs</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Container>
                                <Row className="align-items-center mt-3">
                                    <Col>
                                        <Swiper
                                            navigation
                                            spaceBetween={100}
                                            slidesPerView={3}
                                            autoplay
                                            loop
                                            onSlideChange={() => console.log("video slide change")}
                                            onSwiper={(swiper) => console.log(swiper)}
                                            breakpoints={{
                                                0: {
                                                    slidesPerView: 1,
                                                },
                                                576: {
                                                    slidesPerView: 1,
                                                },
                                                768: {
                                                    slidesPerView: 2,
                                                },
                                                992: {
                                                    slidesPerView: 3,
                                                }
                                            }}
                                        >
                                            {this.state.Records.map((event, index) => (
                                                <SwiperSlide key={index}>
                                                    <div>
                                                        <div className="cat-btn text-center pb-20">
                                                            <Link to={"/program/national-program-detail/"} state={{ id: event.EventsId }} style={{fontFamily:"Merriweather"}}>{event.EventTitle}</Link>
                                                        </div>
                                                        <img src={event.EventImagePath} alt={event.EventTitle} style={{ width: 380, height: 250, borderRadius: 15, objectFit: 'cover' }} />
                                                        <div className="pt-10" >
                                                            <Link to={"/program/national-program-detail/"} state={{ id: event.EventsId }}>
                                                                <p style={{fontFamily:"Merriweather"}}>Location : {event.EventLocation}</p>
                                                                <p style={{fontFamily:"Merriweather"}}>Time : {event.StartTime} - {event.EndTime}</p>
                                                                <p style={{fontFamily:"Merriweather"}}>Date : {event.StartDate} - {event.EndDate}</p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>

                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>
                </>
            );
        }
        else {
            return (
                <>
                    <section className="blog-section p-r z-1 pt-50 pb-50">
                        <div className="container">
                            {this.state.LoadingFlag ? <MyLoader /> : ""}
                            <div className="row align-items-end">
                                <div className="col-xl-7 col-lg-10">
                                    <div className="section-title section-title-left mb-60 wow fadeInLeft">
                                        <span className="sub-title">Featured Programs</span>
                                        <h3 style={{fontFamily: "Open Sans bold"}}>Checkout our upcoming programs</h3>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="button-box float-lg-right mb-60 wow fadeInRight">
                                        <Link to="program/national-program" className="main-btn bordered-btn bordered-yellow" style={{ fontFamily:"Lora" }}>All Programs</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <p style={{fontFamily:"Merriweather"}}>No Records Found</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            );
        }
    }
}