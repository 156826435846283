import React, { Component } from 'react';
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, EffectFade } from "swiper";
import ContentLoader from "react-content-loader";
import 'swiper/css';

const bgImage1 = process.env.PUBLIC_URL + "/assets/images/hero/hero_one-slider-1.jpg";
console.log({ bgImage1 });
// const bgImage2 = process.env.PUBLIC_URL + "/assets/images/hero/hero_one-slider-2.jpg";
// const bgImage3 = process.env.PUBLIC_URL + "/assets/images/hero/hero_one-slider-3.jpg";
// const bgImage4 = process.env.PUBLIC_URL + "/assets/images/hero/hero_one-slider-4.jpg";

SwiperCore.use([Autoplay, Pagination, EffectFade]);

var Api_url = process.env.REACT_APP_API_URL;
var url = Api_url + `/api/wm/yearwise/team/members/lists`;
console.log("API_URL=" + Api_url + ", url=" + url);

var loading1 = Api_url + `/assets/website/images/loading_3.gif`;
console.log("loading1=" + loading1);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

const slides = [];

// const mainSlideOptions = {
//     slidesPerView: 1,
//     loop: true,
//     effect: "fade",
//     pagination: {
//         el: "#main-slider-pagination",
//         type: "bullets",
//         clickable: true,
//     },
//     autoplay: {
//         delay: 5000,
//     },
// };

const MyLoader = (props) => (
    <div style={{ width: "100%", height: "100%" }}>
        <Row>
            <Col xl={12} lg={12} >
                <ContentLoader
                    speed={2}
                    viewBox="0 0 400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                >
                    <rect x="-2" y="-47" rx="0" ry="0" width="595" height="271" />
                </ContentLoader>
            </Col>
        </Row>
    </div >
);

export default class HeroSection extends Component {
    constructor() {
        super();
        this.state = {
            Slides: slides,
            LoadingFlag: false,
            TotalSlides: 0,
        };
    }

    getAllRecords = async () => {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "jci_india_website",
            SettingsName: "home_slideshow",
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = result.length;
                console.log("n1=" + n1);

                this.setState({ LoadingFlag: false, Slides: result, TotalSlides: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        // window.location.replace('https://www.google.com')
        this.getAllRecords();
    }

    render() {
        return (
            <>
                <section className="hero-area-one">
                    <div className="hero-slider-one">
                        {this.state.LoadingFlag ? <MyLoader /> : ""}
                        <Swiper
                            navigation
                            pagination
                            spaceBetween={50}
                            slidesPerView={1}
                            autoplay
                            onSlideChange={() => console.log("slide change")}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            {this.state.LoadingFlag
                                ? ""
                                : this.state.TotalSlides > 0
                                    ? this.state.Slides.map((item, index) => (
                                        <div className="single-slider">
                                            <SwiperSlide key={index}>
                                                    <img className="image-layer bg_cover" width="100%" src={item.FilePath} alt="" />
                                                {item.SettingsValuesDescription ? <p style={{ marginTop: '-78px', marginBottom: '55px', textAlign: 'center', color: 'white', textShadow: '0 0 5px black', fontFamily: 'Helvetica, sans-serif', fontWeight: 1000 }}>{item.SettingsValuesDescription}</p> : ''}

                                                {/* {item.SettingsValuesDescription ? <p style={{ marginTop: '-78px', textAlign: 'center', color: 'white', textShadow: '0 0 5px black', fontFamily: 'Helvetica, sans-serif', fontWeight: 1000 }}>{item.SettingsValuesDescription}</p> : ''} */}
                                            </SwiperSlide>
                                        </div>
                                    ))
                                    : ""}
                        </Swiper>
                    </div>
                </section>
            </>
        );
    }
}