import React, { Component } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import ContentLoader from "react-content-loader";
// import GalleryCard from './GalleryCard';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const MyLoader = (props) => (
    <div style={{ width: "100%", height: "100%" }}>
        <Row>
            <Col xl={12} lg={12} >
                <ContentLoader
                    speed={2}
                    viewBox="0 0 400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                >
                    <rect x="25" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="100" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="175" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="250" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="325" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="25" y="90" rx="5" ry="5" width="50" height="50" />
                    <rect x="100" y="90" rx="5" ry="5" width="50" height="50" />
                    <rect x="175" y="90" rx="5" ry="5" width="50" height="50" />
                    <rect x="250" y="90" rx="5" ry="5" width="50" height="50" />
                    <rect x="325" y="90" rx="5" ry="5" width="50" height="50" />
                </ContentLoader>
            </Col>
        </Row>
    </div >
)

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

export default class GallerySection extends Component {
    constructor() {
        super();
        this.state = {
            Records: [],
            LoadingFlag: false,
            TotalRecords: 0,
            FilePath: '',
            isOpen: false,
        }
    };

    async getAllRecords() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "jci_india_website",
            SettingsName: "gallery",
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = result.length;
                console.log("n1=" + n1);

                this.setState({ LoadingFlag: false, Records: result, TotalRecords: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        // window.location.replace('https://www.google.com')
        this.getAllRecords();
    }

    render() {
        const { FilePath, isOpen } = this.state;
        return (
            <section className="gallery-page pt-120 pb-120">
                <div className="container">
                    {this.state.LoadingFlag ? <MyLoader /> : ""}
                    <div className="gallery-3-col">
                        <Row>
                            {this.state.LoadingFlag ? '' :
                                (this.state.TotalRecords > 0 ?
                                    this.state.Records.map((item, index) => (
                                        <Col xl={3} lg={4} sm={6} >
                                            {isOpen && (
                                                <Lightbox
                                                    mainSrc={FilePath}
                                                    onCloseRequest={() => this.setState({ isOpen: false })}
                                                />
                                            )}
                                            {console.log("isOpen = ", isOpen)}
                                            <div className="gallery-card">
                                                <img src={item.FilePath} onClick={() => this.setState({ isOpen: true, FilePath: item.FilePath })} style={{ width: 330, height: 200, borderRadius: 15, objectFit: 'cover' }} alt="" />
                                                <p style={{ textAlign: 'center' }}>{item.SettingsValuesTitle}</p>
                                            </div>
                                        </Col>

                                    ))
                                    :
                                    <p className="text-center">No Records Found<br/><br/></p>
                                )
                            }
                        </Row>
                    </div>
                </div>
            </section >
        );
    }
}