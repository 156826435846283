import React, { Component } from "react";

const Image1 = process.env.PUBLIC_URL + '/assets/images/bg/jci_india_ngb.jpg';

export default class WhatWeOffers extends Component {
    render() {
        return (
            <>
                {/* <!--====== Start Offer section ======--> */}
                <section className="intro-video-one bg_cover p-r z-1" style={{ backgroundImage: `url(${Image1})` }}>
                    {/* <section className="intro-video-one bg_cover p-r z-1" style="background-image: url(/assets/images/bg/intro-bg-2.jpg);"> */}
                    <div className="container-1350">
                        <div className="services-wrap-one gold-bg pt-65 pb-45 p-r z-1">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb-50 wow fadeInUp">
                                        <span className="sub-title">Why join JCI</span>
                                        <h3 style={{fontFamily:"Open Sans bold"}} >Our activities that chisel a better tomorrow</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                                    <div className="service-box-two text-center mb-35 wow fadeInDown">
                                        <div className="icon">
                                            <img src="/assets/fonts/flaticon/png/11.png" style={{ width: "40px", height: "40px" }} alt=""></img>
                                        </div>
                                        <div className="text">
                                            <h3 className="title">Leadership development programs</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                                    <div className="service-box-two text-center mb-35 wow fadeInUp">
                                        <div className="icon">
                                            <img src="/assets/fonts/flaticon/png/12.png" style={{ width: "40px", height: "40px" }} alt=""></img>
                                        </div>
                                        <div className="text">
                                            <h3 className="title">Self-development trainings</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                                    <div className="service-box-two text-center mb-35 wow fadeInDown">
                                        <div className="icon">
                                            <img src="/assets/fonts/flaticon/png/13.png" style={{ width: "40px", height: "40px" }} alt=""></img>
                                        </div>
                                        <div className="text">
                                            <h3 className="title">Business development connections</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                                    <div className="service-box-two text-center mb-35 wow fadeInUp">
                                        <div className="icon">
                                            <img src="/assets/fonts/flaticon/png/14.png" style={{ width: "40px", height: "40px" }} alt=""></img>
                                        </div>
                                        <div className="text">
                                            <h3 className="title">Community benefitting projects</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                                    <div className="service-box-two text-center mb-35 wow fadeInDown">
                                        <div className="icon">
                                            <img src="/assets/fonts/flaticon/png/15.png" style={{ width: "40px", height: "40px" }} alt=""></img>
                                        </div>
                                        <div className="text">
                                            <h3 className="title">Uplifting the economically deprived</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                                    <div className="service-box-two text-center mb-35 wow fadeInUp">
                                        <div className="icon">
                                            <img src="/assets/fonts/flaticon/png/16.png" style={{ width: "40px", height: "40px" }} alt=""></img>
                                        </div>
                                        <div className="text">
                                            <h3 className="title">Together building a better world</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="play-content-box text-center wow fadeInDown">
                                {/* <a href="https://www.youtube.com/watch?v=gOZ26jO6iXE" className="video-popup"><i className="fas fa-play"></i></a> */}
                            </div>
                        </div>
                    </div>

                </section>
                {/* <!--====== End Offer section ======--> */}
            </>
        );
    }
}