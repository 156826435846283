import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import packageJson from '../../package.json';
console.log("version = ",packageJson.version);

var version=packageJson.version;
var Api_url = process.env.REACT_APP_API_URL;

var image1 = Api_url + `/assets/website/images/app_store.png`;
console.log('image1=' + image1);

var CurrentYear = new Date().getFullYear();

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

export default class Footer extends Component {
    constructor() {
        super();
        this.state = {
            VersionInfo: ""
        };
    }
    async getVersionInfo() {
        console.log("getVersionInfo called ");
        var url = Api_url + `/api/pages/version-info`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 2
        };
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                this.setState({ VersionInfo: records, });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };
    componentDidMount() {
        console.log("componentDidMount called ");
        this.getVersionInfo();
    }

    render() {
        return (
            <>
                <footer className="footer-default footer-white dark-black-bg">
                    <div className="container">
                        <div className="footer-top wow fadeInUp">
                            <div className="row">
                                <div className="col-lg-4 col-md-12 footer-contact-item">
                                    <div className="contact-info d-flex justify-content-center">
                                        <div className="site-logo text-center">
                                            <Link to={"/"} className="brand-logo"> <img src="/assets/images/logo/jci-logo.png" alt="Jci-India-Logo"></img> </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 footer-contact-item">
                                    <div className="contact-info d-flex">
                                        <div className="icon">
                                            <i className="flaticon-placeholder"></i>
                                        </div>
                                        <div className="text">
                                            <h5>Locations</h5>
                                            <p>National Head Quarters 506 Windfall, Sahar Plaza, J.B. Nagar, Andheri (EAST) Mumbai 400 059</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 footer-contact-item">
                                    <div className="contact-info d-flex">
                                        <div className="icon">
                                            <i className="flaticon-email"></i>
                                        </div>
                                        <div className="text">
                                            <h5>Email Us</h5>
                                            <h6><a href="mailto:support@jciindia.in">support@jciindia.in</a></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-widget pt-70 pb-25">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12">
                                    <div className="footer-widget about-widget mb-40 wow fadeInDown">
                                        <h4 className="widget-title">About Us</h4>
                                        <div className="footer-about-content">
                                            <p className="text-justify">JCI India is the Second largest Member Nation of Junior Chamber International. Currently we are active in more than 26 states and union territories across India.</p>
                                            <div className="social-box">
                                                <h4 className="mb-15">Follow On</h4>
                                                <ul className="social-link">
                                                    <li><a href={onclick="return: false"}><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href={onclick="return: false"}><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href={onclick="return: false"}><i className="fab fa-linkedin"></i></a></li>
                                                    <li><a href={onclick="return: false"}><i className="fab fa-youtube"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-lg-4 col-md-7 col-sm-12">
                                    <div className="footer-widget footer-nav-widget mb-40 wow fadeInUp">
                                        <h4 className="widget-title">Quick Links</h4>
                                        <div className="footer-widget-nav">
                                            <ul>
                                                <li><Link to="/about-jci">About us</Link></li>
                                                <li><Link to="/content/terms-of-service">Terms Of Service</Link></li>
                                                <li><Link to="/online-payment">Payment</Link></li>
                                                <li>
                                                    <a href="https://foundation.jciindia.in/" target="_blank" rel="noopener noreferrer">
                                                        JCI India Foundation
                                                    </a>
                                                </li>
                                                <li><Link to="/contact-us">Contact Us</Link></li>
                                            </ul>
                                            <ul>
                                                <li><Link to="/content/privacy-statement">Privacy and Policy</Link></li>
                                                <li><Link to="/content/return-and-refund-policy">Return & refund</Link></li>
                                                <li>
                                                    <a target="_blank" href="https://members.jciindia.in/" rel="noopener noreferrer">
                                                        Members Portal Login
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://seniors.jciindia.in/" target="_blank" rel="noopener noreferrer">
                                                        Senior Members Association
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-lg-4 col-md-6 col-sm-12">
                                    <div className="footer-widget recent-post-widget mb-10 wow fadeInDown">
                                        <h4 className="widget-title mb-0">Download Android App</h4>
                                        <ul className="post-widget-wrap">
                                            <a target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.jciindia.directory&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img width="300px" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
                                        </ul>
                                    </div>
                                    <div className="footer-widget recent-post-widget mb-10 wow fadeInDown">
                                        <h4 className="widget-title mb-0">Download iOS App</h4>
                                        <ul className="post-widget-wrap">
                                            <a href="https://apps.apple.com/in/app/jci-india-official-directory/id1579165277" target="_blank" rel="noopener noreferrer">
                                                <img src={image1} alt="" width="300px" />
                                            </a>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-copyright">
                            <div className="col-lg-12">
                                <div className="copyright-text text-center">
                                    <p>
                                        © Copyright {CurrentYear} by JCI India &nbsp; &nbsp; &nbsp; &nbsp; Version - {version} &nbsp; &nbsp; &nbsp; &nbsp; Crafted by Artisans of <a href="https://vaagai.org.in" target="_blank" rel="noopener noreferrer"><span style={{color: 'blue' }}>Vaagai Tecknowledge</span></a>
                                    </p>
                                    <br/>
                                    <p className="text-center">{this.state.VersionInfo}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}