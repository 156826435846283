import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import NoPage from "./pages/NoPage";
import Home from "./pages/Home";
// import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Gallery from "./pages/Gallery";
import NationalProgram from "./pages/program/NationalProgram"
import NationalGoverningBoard from "./pages/team/NationalGoverningBoard";
import NomCoordinators from "./pages/team/NomCoordinators";
import PastNationalPresidents from "./pages/team/PastNationalPresidents";
import ZoneGoverningBoard from "./pages/team/ZoneGoverningBoard";
import NationalCommittees from "./pages/team/NationalCommittees";
import JciIndiaNhqStaffs from "./pages/team/JciIndiaNhqStaffs";
import NationalProgramDetail from "./pages/program/NationalProgramDetail";
import Download from "./pages/Download";
import NationalEvent from "./pages/event/NationalEvent";
import ZoneEvent from "./pages/event/ZoneEvent";
import LOEvent from "./pages/event/LOEvent";
import TermsOfService from "./pages/content/TermsOfService";
import ReturnAndRefundPolicy from "./pages/content/ReturnAndRefundPolicy";
import PrivacyStatement from "./pages/content/PrivacyStatement";
import OnlinePayment from "./pages/OnlinePayment";
import AboutJCI from "./pages/about-us/AboutJCI";
import JCIIndia from "./pages/about-us/JCIIndia";
import NPCorner from "./pages/about-us/NPCorner";

export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about-jci" element={<AboutJCI />} />
          <Route path="about-jci-india" element={<JCIIndia />} />
          <Route path="about-np-corner" element={<NPCorner />} />
          {/* <Route path="about-us" element={<AboutUs />} /> */}
          <Route path="gallery" element={<Gallery />} />
          <Route path="event/national-event" element={<NationalEvent />} />
          <Route path="event/zone-event" element={<ZoneEvent />} />
          <Route path="event/lo-event" element={<LOEvent />} />
          <Route path="program/national-program" element={<NationalProgram />} />
          {/* <Route path="program/national-program-detail/:id" element={<NationalProgramDetail />} /> */}
          <Route path="program/national-program-detail" element={<NationalProgramDetail />} />
          <Route path="team/national-governing-board" element={<NationalGoverningBoard />} />
          <Route path="team/zone-governing-board" element={<ZoneGoverningBoard />} />
          <Route path="team/national-committees" element={<NationalCommittees />} />
          <Route path="team/nom-coordinators" element={<NomCoordinators />} />
          <Route path="team/past-national-presidents" element={<PastNationalPresidents />} />
          <Route path="team/jci-india-nhq-staffs" element={<JciIndiaNhqStaffs />} />
          <Route path="download" element={<Download />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="content/terms-of-service" element={<TermsOfService />} />
          <Route path="content/return-and-refund-policy" element={<ReturnAndRefundPolicy />} />
          <Route path="content/privacy-statement" element={<PrivacyStatement />} />
          <Route path="online-payment" element={<OnlinePayment />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);