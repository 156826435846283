import React from "react";
import { Container, Row, Col } from "react-bootstrap";

var Api_url = process.env.REACT_APP_API_URL;
var url = Api_url + `/api/wm/yearwise/team/members/lists`;
console.log('API_URL=' + Api_url + ', url=' + url);

var loading1 = Api_url + `/assets/website/images/loading_3.gif`;
console.log('loading1=' + loading1);

const LoadingContent = () => {
    return (
        <Container>
            <Row>
                <Col className="text-center">
                    <img src={loading1} alt="" width="300px" />
                </Col>
            </Row>
        </Container>
    );
};

export default LoadingContent;
