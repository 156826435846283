import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageBanner from "../../components/service/PageBanner";
import TeamPage from "../../components/team/TeamPage";
import LoadingContent from "../../components/LoadingContent";

import Select from "react-select";
import axios from "axios";
import { Helmet } from "react-helmet";

var Api_url = process.env.REACT_APP_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class ZoneGoverningBoard extends Component {
    constructor() {
        super();
        this.state = {
            members: [],
            isClearable1: true,
            isDisabled1: false,
            isLoading1: false,
            isSearchable1: true,
            ZoneOptions: [],
            zoneId: 2,
            zoneName: "Zone I",
            LoadingFlag: false,
            TotalRecords: 0,
        };
    }

    async fetchData(e){
        console.log("fetchData called ");
        var ZoneId = e.value;
        this.setState({ zoneId: e.value, zoneName: e.label });
        this.setState({ LoadingFlag: true, members:[] });
        var url = Api_url + `/api/governing-board/members-lists`;
        console.log("url=" + url + ", ZoneId=" + ZoneId);
        var postData = {
            UserInterface: 2,
            ZoneCode: ZoneId,
        };
        console.log("postData=" + JSON.stringify(postData));
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var result = res.data.records;
                var n1 = result.length;
                console.log("n1=" + n1);
                console.log("result=" + JSON.stringify(result));
                this.setState({ LoadingFlag: false, TotalRecords: n1, members: result });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getZones(){
        console.log("getZones called ");
        var url = Api_url + `/api/wm/zone/options`;
        console.log("url=" + url);
        var postData = {
            additional: {
                UserInterface: 2
            }
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ isLoading1: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var result = res.data.records;
                console.log("result=" + JSON.stringify(result));
                var options = result.map(d => ({
                    value: d.value,
                    label: d.text
                }));
                console.log("options=" + JSON.stringify(options));
                this.setState({ ZoneOptions: options });
                this.setState({ isLoading1: false });
                this.fetchData({ value: this.state.zoneId, label: this.state.zoneName });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        window.scrollTo(0, 0);
        this.getZones();
    }
    render() {
        return (
            <>
            <Helmet>
                <title>Zone Governing Board | JCI India | Be Better</title>
                {/* <meta name='description' content='About-JCI India' /> */}
            </Helmet>
                <PageBanner bannerDetails={{ bannerTitle: "ZoneGoverning Board", bannerActive: "Team" }} />
                <Container>
                    <br /><br /><br />
                    <Row className=" align-items-center">
                        <Col md={6} lg={6}>Select Zone</Col>
                        <Col md={6} lg={6} style={{ zIndex: 10 }}>
                            <Select
                                options={this.state.ZoneOptions}
                                onChange={this.fetchData.bind(this)}
                                isClearable={this.state.isClearable1}
                                isSearchable={this.state.isSearchable1}
                                isDisabled={this.state.isDisabled1}
                                isLoading={this.state.isLoading1}
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col md={12} lg={12}> <h3> {this.state.zoneName} </h3> </Col>
                    </Row>
                </Container>
                {this.state.TotalRecords > 0 ? <TeamPage members={this.state.members} /> : <p className="text-center">No Records Found<br/><br/></p>}
                {this.state.LoadingFlag ? <LoadingContent /> : ''}
            </>
        );
    }
}