import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ProgramDetail from "../../components/program/ProgramDetail";
import PageBanner from "../../components/service/PageBanner";

export default class NationalProgramDetail extends Component {

    componentDidMount() {
        console.log("national program detail componentDidMount called ");
        let currentLocation = window.location.toString();
        console.log({ currentLocation });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>National Program | JCI India | Be Better</title>
                    {/* <meta name='description' content='About-JCI India' /> */}
                </Helmet>
                <PageBanner bannerDetails={{ bannerTitle: "National Program Detail", bannerActive: "National Program Detail" }} />
                <ProgramDetail data={window.location} />
            </>
        );
    }
}