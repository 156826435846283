import React, { Component } from "react";
import ContactInformationOne from '../components/contact/ContactInformationOne';
import PageBanner from "../components/service/PageBanner";
import { Helmet } from "react-helmet";

export default class ContactUs extends Component {
  componentDidMount() {
    console.log("componentDidMount called");
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
      <Helmet>
          <title>Contact Us | JCI India | Be Better</title>
          {/* <meta name='description' content='About-JCI India' /> */}
      </Helmet>
        <PageBanner bannerDetails={{ bannerTitle: "Contact Us", bannerActive: "Contact" }} />
        <ContactInformationOne />
      </>
    );
  }
}